import React from 'react';

import LabeledPin from '../../molecules/LabeledPin/labeled-pin';
import assetTypes from '../../../models/assetTypes';
import './asset-type-legend.scss';

export default () => {
  return (
    <div className="asset-type-legend">
      {Object.keys(assetTypes).map((key) => (
        <LabeledPin key={key} type={key} />
      ))}
    </div>
  );
};
