import React, { Component } from 'react';
import Button from '../../atoms/Button/button';
import Input from '../../atoms/Input/input';

import AssetTypeLegend from '../../organisms/AssetTypeLegend/asset-type-legend';

// Styles
import './style-guide.scss';

class StyleGuide extends Component {
  render() {
    return (
      <div className="app-container">
        <p>Success Button with Icon</p>
        <Button icon="fa-shopping-bag" className="btn-success" text="My Cart" />
        <p>Primary Button</p>
        <Button className="btn-primary" text="Request Early Access" />
        <p>Danger Button</p>
        <Button className="btn-danger" text="Remove Item" />
        <p>Icon Button</p>
        <Button icon="fa-cart-plus" className="btn-danger" />
        <p>Input</p>
        <br />
        <Input
          labelName="Label Name"
          placeHolder="Enter your email address"
          name="test"
        />
        <p>Asset Type Legend</p>
        <AssetTypeLegend />
      </div>
    );
  }
}

export default StyleGuide;
