import React from 'react';
import classNames from 'classnames';

// Styles
import './input.scss';

const Input = ({
  name,
  value,
  onChange,
  onBlur,
  type,
  required,
  labelName,
  className,
  placeHolder,
}) => {
  return (
    <div className={classNames('form-input', className)}>
      <input
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        type={type}
        required={required}
        placeholder={placeHolder}
        className="form-input__element"
      />
      <label className="form-input__label">{labelName}</label>
    </div>
  );
};

export default Input;
