/**
 * Attributes model for asset types.
 * configure css selectors, display text, attribute display, etc.
 */
export const assetTypes = {
  aerialBanner: {
    selector: 'aerial-banner',
    displayText: 'Aerial Banner',
    isStatic: false,
  },
  bench: {
    selector: 'bench',
    displayText: 'Bench',
    isStatic: true,
  },
  busShelter: {
    selector: 'bus-shelter',
    displayText: 'Bus Shelter',
    isStatic: true,
  },
  digitalBillboard: {
    selector: 'digital-billboard',
    displayText: 'Digital Billboard',
    isStatic: true,
  },
  elevatorWrap: {
    selector: 'elevator-wrap',
    displayText: 'Elevator Wrap',
    isSatic: true,
  },
  mapStand: {
    selector: 'map-stand',
    displayText: 'Map Stand',
    isStatic: true,
  },
  mobileBillboard: {
    selector: 'mobile-board',
    displayText: 'Mobile Billboard',
    isStatic: false,
  },
  poster: {
    selector: 'poster',
    displayText: 'Poster',
    isStatic: true,
  },
  staticBillboard: {
    selector: 'static-billboard',
    displayText: 'Static Billboard',
    isStatic: true,
  },
  transitAd: {
    selector: 'transit-ad',
    displayText: 'Transit Ad',
    isStatic: true,
  },
  other: {
    selector: 'default',
    displayText: 'Other',
    isStatic: true,
  },
};

export default assetTypes;
